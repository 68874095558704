@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Roboto:wght@300;500;600&display=swap');
// Bootstrap
@import '~bootstrap/scss/bootstrap-grid';
@import '~bootstrap/scss/bootstrap-reboot';
@import "pluigns/lity.min.css";
@import "pluigns/sweetalert2.min.css";
@import "pluigns/nav.min.css";

//
@import "general";
@import 'login';
@import "nav";
