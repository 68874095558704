.navbar-dark .navbar-nav .nav-link {
    color: $brand-primary;
}

nav {
    //background-image: url('/img/admin/login/back-wrap.png');
    //background-image: url('/back/back-nav.webp');
    //background-position: 0 0;
    //background-size: auto;
    //background-repeat: repeat;
    background-color: $brand-primary;
    width: 285px !important;
    z-index: 0 !important;

    -webkit-box-shadow: 0px 0px 16px -2px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 16px -2px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 16px -2px rgba(0,0,0,0.5);

    .navbar-brand {
        background-color: white;
        height: 113px;
        text-align: center;
        padding-top: 20px;

        img {
            width: 120px;
        }
    }

    .navbar-nav {
        display: flex;
        flex-direction: column;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
    }

    ul {
        &.navbar-nav {
            margin-top: 40px;

            li {
                //background-image: url('/img/admin/login/back-wrap.png');
                padding-top: 10px;
                padding-bottom: 10px;
                border-radius: 5px;
                position: relative;

                &:before {
                    background-color: rgb(255, 255, 255, 0.14);
                    bottom: 0;
                    content: '';
                    height: 1px;
                    position: absolute;
                    width: 100%;
                }

                &.active {
                    background-color: $brand-secondary;
                    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
                    border-radius: 0;

                    a {
                        color: white !important;
                        font-weight: bold;

                        &.no-active {
                            color: inherit !important;
                        }
                    }
                }

                .nav-link {
                    color: white !important;
                    padding-left: 25px !important;
                    text-decoration: none;
                    text-shadow: 0px 0px 2px black;
                }

                .dropdown-menu {
                    //background-image: url('/img/admin/login/back-wrap.png');
                    background-color: rgb(255, 255, 255, 0.9);
                    border-radius: 5px;
                    margin-left: 10px;

                    a {
                        &.active {
                            background-color: $brand-primary;
                            color: white !important;
                        }
                    }

                    .dropdown-item {

                        &:hover {
                            background-color: $brand-primary;
                            color: white;
                        }
                    }
                }

            }
        }
    }

    figure {
        margin: 0 auto;
        padding: 0;
        text-align: center;
        width: 100%;

        .avocado {
            margin-top: 20px;
            width: 120px;

            @include media-breakpoint-up(md) {
                margin: 45px auto 0 auto;
                width: 35%;
            }
        }
    }

    .dropdown-toggle:after {
        display: inline-block;
        margin-left: .255em;
        vertical-align: .255em;
        content: "";
        border-top: .3em solid;
        border-right: .3em solid transparent;
        border-bottom: 0;
        border-left: .3em solid transparent;
    }
    .navbar.fixed-left .navbar-nav .nav-item .dropdown-toggle:after {
        border-top: .3em solid transparent;
        border-left: .3em solid;
        border-bottom: .3em solid transparent;
        border-right: none;
        vertical-align: baseline;
    }

    .navbar-toggler {
        padding: .25rem .75rem;
        font-size: 1.125rem;
        line-height: 1;
        background-color: transparent;
        border: 1px solid transparent;
        border-radius: .25rem;
    }

    .navbar-expand-md .navbar-toggler {
        display: none;
    }
}

#content {

    .head {
        background-image: url('/back/login/back-wrap.png');
        height: 70px;
        padding-top: 15px;
        margin-bottom: 35px;
        width: 100%;

        .tab {
            height: 54px;
            padding-top: 15px;

            a {
                color: #212121;
            }

            &.active, &:hover {
                background-color: white;
                border-radius: 10px 10px 0 0;

                a {
                    color: $brand-primary;
                }
            }
        }
    }
}
